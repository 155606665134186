.bot-chat {
  display: grid;
  grid-template-rows: 36px 1fr 50px;
  box-sizing: border-box;
  padding: 20px 4px 30px 4px;
  background: linear-gradient(
    180deg,
    rgba(90, 52, 240, 0.1) 0%,
    rgba(90, 52, 240, 0) 100%
  );
}

.bot-chat__header {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #fd5862;
  text-align: center;
  padding-bottom: 10px;
}

.bot-chat__send-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;

  border-width: 0;
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bot-chat__window {
  height: 100%;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.05));
}

.bot-chat__window-chat::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  margin-right: 20px;
}

.bot-chat__window-chat::-webkit-scrollbar-track {
  border-radius: 20px;
}

.bot-chat__window-chat::-webkit-scrollbar-thumb {
  background-color: #adacc5;
  border-radius: 20px;
}

.bot-chat__window-header {
  background-color: var(--bg-color);
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.bot-chat__window-header .chat__avatar_top {
  display: none;
}

.bot-chat__avatar {
  display: none;
}

.bot-chat__window-chat {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: auto;
  padding: 0;
  max-width: 800px;
  margin: 0 auto;
}

.bot-chat__window-chat .chat__message_bot_typing {
  margin-left: 30px;
}

.bot-chat__window-chat .chat__message_bot {
  color: black;
  background-color: white;
}

.bot-chat__window-chat .chat__message_user {
  color: white;
  background-color: var(--main-color);
}

.bot-chat__window-chat .chat__message_user {
  margin-left: auto;
  margin-right: 12px;
}

.bot-chat__volume-btn {
  color: var(--romantic-primary-color);
  margin-right: 26px;
  cursor: pointer;
}

.bot-chat__volume-btn_disabled {
  color: rgba(0, 0, 0, 0.2);
}

.bot-chat__back {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  cursor: pointer;
}

.bot-chat__back-text {
  color: var(--main-color);
  margin: 0 0 0 4px;
  font-weight: 800;
}

.bot-chat__window-chat .message__text {
  font-size: 17px;
  line-height: 24px;
  white-space: pre-line;
}

.smart-replies__row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  column-gap: 20px;
  margin: 20px 12px 0 12px;
}

.smart-replies__row_single {
  justify-content: flex-end;
}

.smart-replies__row::-webkit-scrollbar {
  width: 6px;
  height: 0px;
  margin-right: 20px;
}

.smart-replies__row::-webkit-scrollbar-track {
  border-radius: 20px;
}

.smart-replies__row::-webkit-scrollbar-thumb {
  background-color: #adacc5;
  border-radius: 20px;
}

.smart-replies__row .reply {
  height: fit-content;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border-width: 1px;

  min-height: 40px;
  color: #5a34f0;
  background: white;
  border-radius: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  box-sizing: border-box;
  margin: auto 0 0 0;
  text-align: start;
}

.smart-replies__row .reply:hover {
  border-width: 1px;
}

.smart-replies__row .reply:not(:last-of-type) {
  margin-right: 10px;
}

@media screen and (max-width: 500px) {
  .bot-chat {
    height: 100%;
  }

  .bot-chat__window-chat {
    height: 310px;
  }

  .bot-chat__window-header .chat__avatar_top {
    right: calc(50% - 35px);
    display: block;
  }

  .bot-chat__avatar {
    position: absolute;
    top: 22px;
    right: calc(50% - 40px);
    width: 80px;
    aspect-ratio: 1/1;
    border-radius: 10px;
    display: block;
  }

  .smart-replies__row {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    margin: 20px 0 0 0;
    padding-bottom: 10px;
  }

  .smart-replies__row .reply {
    white-space: nowrap;
    padding: 12px;
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: flex-start;
  }
}

.bot-chat__input {
  width: calc(100% - 12px * 2);
  margin: 0 auto;
  max-width: 800px;
}

.bot-chat__input .MuiInputBase-root input,
textarea {
  color: black !important;
}

.chat__message-row {
  margin-left: 12px;
  margin-top: 20px;
}
