.other-bot-tile {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 20px;
  padding: 12px;
  align-items: center;
  cursor: pointer;
  text-overflow: ellipsis;
  color: inherit;
  text-decoration: none;
  position: relative;
}

.other-bot-tile:not(:last-of-type) {
  margin-bottom: 16px;
}

.other-bot-tile__image {
  width: 48px;
  height: 48px;
  border-radius: 12px;
}

.other-bot-tile__name {
  margin-left: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: start;
  max-width: 180px;
}

.ohter-bot-tile__lock {
  padding: 8px;
  box-sizing: border-box;
  border: 2px solid var(--main-color);
  margin-left: auto;
  position: absolute;
}

.ohter-bot-tile__lock-img {
  position: absolute;
  right: 12px;
  top: 25%;
  background: white;
}

.other-bot-tile__locked .other-bot-tile__name,
.other-bot-tile__locked .other-bot-tile__image {
  opacity: 0.5;
}

.other-bot-tile__locked:hover .ohter-bot-tile__lock-img {
  display: none;
}

.ohter-bot-tile__lock-btn.inbutton.MuiButtonBase-root {
  display: none;
}

.ohter-bot-tile__lock-btn.inbutton.MuiButtonBase-root img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.ohter-bot-tile__lock-btn.inbutton.MuiButtonBase-root p {
  font-size: 14px;
}

.other-bot-tile__locked:hover
  .ohter-bot-tile__lock-btn.inbutton.MuiButtonBase-root {
  display: flex;
  padding: 8px 16px;
  box-sizing: border-box;
  border: 2px solid var(--main-color);
  border-radius: 36px;
  margin-left: auto;
  position: absolute;
  right: 12px;
  top: 25%;
  background: white;
  width: 104px;
  height: 36px;
}

.ohter-bot-tile__lock-btn.inbutton.MuiButtonBase-root.MuiButton-root:hover {
  background: var(--main-color);
  opacity: 1;
}

@media screen and (max-width: 800px) {
  .other-bot-tile__locked:hover
    .ohter-bot-tile__lock-btn.inbutton.MuiButtonBase-root {
    display: none;
  }

  .other-bot-tile__locked:hover .ohter-bot-tile__lock-img {
    display: block;
  }
}

@media screen and (max-width: 1150px) {
  .other-bot-tile__image {
    width: 36px;
    height: 36px;
  }

  .other-bot-tile__name {
    margin-left: 12px;
    font-size: 14px;
    line-height: 18px;
    max-width: 120px;
  }
}
