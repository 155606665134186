body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-color: #5A34F0;
  --bg-color: #F1EEFF;
  --secondary-color: #333333;
  --light-purp-color: #ABA3BC;
  --input-color: #f7f5ff;
	--border-radius: 20px;
  --chat-bg-color: #f9f9f9;
  --border-color: #e8e8e8;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.text-block {
  background-color: var(--input-color);
	color: var(--light-purp-color);
	box-shadow: none;
	border-radius: 16px;
	border: none;
	resize: none;
	font-style: normal;
	font-size: 18px;
	line-height: 21px;
	color: var(--secondary-color);
}

.text-block::placeholder {
  color: var(--light-purp-color);
}