.main {
  background: #f1eeff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #292841;
  text-align: center;
}

.main .main__title {
  font-weight: 900;
  font-size: 72px;
  line-height: 100px;

  margin-top: 80px;
}

.main .main__subtitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

.main .categories {
  margin-top: 80px;
  position: relative;
}

.main .categories .title {
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
}

.main .categories .block {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 1140px;
  justify-content: center;
}

.main .categories .block .tag {
  margin-top: 16px;
}

.main .profs {
  margin-top: 48px;
  width: 1250px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 27px;
}

.main .how {
  margin-top: 120px;
  font-weight: 900;
  font-size: 60px;
  line-height: 73px;
}

.main .how__subtitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-top: 10px;
}

.main .cards {
  margin-top: 70px;
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.main .try-btn.inbutton {
  margin-top: 60px;
  width: 247px;
  height: 64px;
  background: radial-gradient(
      100% 1694.69% at 100.2% 100%,
      rgba(186, 169, 255, 0.3) 0%,
      rgba(90, 52, 240, 0) 100%
    ),
    #5a34f0;
}

.main .why {
  margin-top: 120px;
  font-weight: 900;
  font-size: 60px;
  line-height: 73px;
  width: calc(100% - 40px);
}

.main .big-cards {
  margin-top: 72px;
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.main .chat-title {
  margin-top: 48px;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
}

.main .chat-btn.inbutton {
  margin-top: 48px;
  width: 269px;
  height: 64px;
  background: radial-gradient(
      100% 1694.69% at 100.2% 100%,
      rgba(186, 169, 255, 0.3) 0%,
      rgba(90, 52, 240, 0) 100%
    ),
    #5a34f0;
}

.main .footer {
  margin-top: 108px;
}

.left-arrow::after {
  height: 82px;
  width: 80px;
  content: '';
  pointer-events: none;
  background: linear-gradient(
    to right,
    #e9e4fe 35%,
    rgba(255, 255, 255, 0) 100%
  );
  /* background: green; */
  position: absolute;
  z-index: 100;
  top: inherit;
  left: -10px;
}

.right-arrow {
  position: absolute;
  height: 34px;
  width: 0px;
  left: 20px;
  display: none;
}

.left-arrow {
  position: absolute;
  height: 34px;
  width: 0px;
  margin-left: -3px;
  display: none;
}

.right-arrow::before {
  height: 82px;
  width: 80px;
  content: '';
  pointer-events: none;
  background: linear-gradient(
    to left,
    #e9e4fe 35%,
    rgba(255, 255, 255, 0) 100%
  );
  /* background: yellow; */
  position: absolute;
  z-index: 100;
  top: inherit;
  left: inherit;
}

.arrow-img {
  position: absolute;
  height: 20px;
  width: 20px;
  z-index: 102;
  cursor: pointer;
}

.left-arrow-img {
  transform: rotateY(180deg);
}

.main__chat {
  margin-top: 80px;
  width: 100%;
}

@media screen and (max-width: 1300px) {
  .main .profs {
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
  }

  .main .cards {
    margin: 50px 40px 0 40px;
    width: 100%;
  }

  .main .big-cards {
    margin-top: 52px;
    width: 100%;
  }

  .main .categories .block {
    width: 900px;
    max-width: 1200px;
  }
}

@media screen and (max-width: 900px) {
  .main .profs {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  .main .cards {
    flex-direction: column;
    align-items: center;
  }

  .main .big-cards {
    flex-direction: column;
    align-items: center;
  }

  .main .categories .block {
    width: 750px;
  }
}

@media screen and (max-width: 758px) {
  .left-arrow {
    display: contents;
  }

  .right-arrow {
    display: contents;
  }

  .main .main__title {
    font-weight: 900;
    font-size: 36px;
    line-height: 48px;
    margin-top: 48px;
    width: calc(100% - 80px);
  }

  .main .main__subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 8px;
    width: calc(100% - 80px);
  }

  .main .categories {
    margin: 48px 0 0 0;
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
  }

  .main .categories .title {
    font-size: 18px;
    line-height: 24px;
    width: calc(100% - 40px);
    align-self: center;
  }

  .main .categories .block {
    width: 100%;
    margin-top: 20px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
    padding-bottom: 15px;
    padding-left: 10px;
  }

  .main .categories .block::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    margin-right: 20px;
  }

  .main .categories .block::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  .main .categories .block::-webkit-scrollbar-thumb {
    background-color: #e7e4f7;
    border-radius: 20px;
  }

  .main .profs {
    margin-top: 32px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  .main .how {
    margin-top: 80px;
    font-size: 36px;
    line-height: 44px;
  }

  .main .how__subtitle {
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
  }

  .main .try-btn.inbutton {
    margin: 32px 20px 0 20px;
    width: calc(100% - 40px);
    font-weight: 900;
    font-size: 21px;
    line-height: 32px;
  }

  .main .why {
    margin-top: 80px;
    font-size: 36px;
    line-height: 44px;
  }

  .main .cards {
    margin: 40px 20px 0 20px;
    width: calc(100% - 40px);
  }

  .main .big-cards {
    margin: 40px 20px 0 20px;
    width: calc(100% - 40px);
  }

  .main .chat-title {
    margin-top: 30px;
    font-size: 21px;
    line-height: 28px;
  }

  .main .chat-btn.inbutton {
    margin: 32px 20px 0px 20px;
    width: calc(100% - 40px);
    font-weight: 900;
    font-size: 21px;
    line-height: 32px;
  }

  .main .footer {
    margin-top: 58px;
  }
}

@media screen and (max-width: 500px) {
  .main {
    background: #e9e4fe;
  }

  .main .chat-title {
    width: calc(100% - 40px);
  }
}
