.spinner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}

.spinner-layout .spinner {
  justify-content: flex-start;
}

.spinner-layout {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(1, 1, 1, 0.2);
  width: 100%;
  height: 100vh;
  z-index: 3;
}
