.footer {
  background: rgba(0, 0, 0, 0.02);
  /* backdrop-filter: blur(22px); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #8a89ab;
  padding: 50px 265px;
  width: 100%;
  box-sizing: border-box;
}

.footer a {
  color: #8a89ab;
}

.footer .footer__links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer__links_margin a:not(:first-of-type) {
  margin-left: 16px;
}

.footer .footer__links p {
  margin: 0 5px;
}

@media screen and (max-width: 1300px) {
  .footer {
    padding: 50px 100px;
  }
}

@media screen and (max-width: 758px) {
  .footer {
    flex-direction: column;
    padding: 34px 0;
  }
  .footer .footer__links {
    margin-top: 12px;
  }
}
