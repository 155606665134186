.card {
	width: 374px;
	height: 396px;
	background: rgba(90, 52, 240, 0.05);
	border-radius: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-right: 40px;
	padding: 60px;
	box-sizing: border-box;
}

.card:last-of-type {
	margin-right: 0;
}

.card .card__number {
	width: 72px;
	height: 72px;
	background: #ffffff;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
	border-radius: 150px;
	position: absolute;
	left: 150px;
	top: -36px;
	color: #8a88ac;
	font-weight: 900;
	font-size: 36px;
	line-height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.card .card__text {
	font-weight: 900;
	font-size: 24px;
	line-height: 32px;
	color: #292841;
	text-align: center;
	letter-spacing: 0.1px;
	margin-top: 40px;
}

@media screen and (max-width: 1300px) {
	.card {
		padding: 30px;
		width: 274px;
	}

	.card .card__number {
		left: calc((100% / 2) - 36px);
	}
}

@media screen and (max-width: 900px) {
	.card {
		width: 80%;
		margin-right: 0;
		margin-bottom: 40px;
		height: 300px;
	}

	.card:last-of-type {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 758px) {
	.card {
		width: 100%;
		margin-right: 0;
		margin-bottom: 40px;
		height: 152px;
		flex-direction: row;
		padding: 40px 30px;
		align-items: center;
		justify-content: flex-start;
	}

	.card:last-of-type {
		margin-bottom: 0;
	}

	.card .card__img {
		width: 64px;
		height: 64px;
	}

	.card .card__text {
		font-size: 18px;
		line-height: 24px;
		margin-left: 30px;
		margin-top: 0;
		text-align: start;
	}

	.card .card__number {
		width: 44px;
		height: 44px;
		font-size: 24px;
		line-height: 32px;
		left: calc((100% / 2) - 22px);
		top: -22px;
	}
}
