.bot-chat-header {
	display: flex;
	flex-direction: row;
	width: 100%;
	box-sizing: border-box;
	padding: 22px 60px;
	background-color: white;
	justify-content: space-between;
	align-items: center;
}

.bot-chat-header_bot {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}
.bot-chat-header__links {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.bot-chat-header__links .user-logo-container {
	margin-left: auto;
}

.bot-chat-header__link {
	color: #5a34f0;
	font-weight: 900;
	font-size: 18px;
	line-height: 24px;
	margin-right: 30px;
}

.bot-chat-header__link:last-of-type {
	margin-right: 0;
}

.bot-chat-header__logo {
	cursor: pointer;
}

.bot-chat-header__back {
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}

.bot-chat-header__back-img {
	margin-right: 12px;
}

.bot-chat-header__back-text {
	font-weight: 900;
	font-size: 18px;
	line-height: 24px;
	color: #5a34f0;
}

.login-btn.inbutton.MuiButton-root {
	width: 128px;
	height: 40px;
	margin-left: 40px;
}

.bot-chat-header__logo-block {
	height: 40px;
}

@media screen and (max-width: 758px) {
	.bot-chat-header__link_dot {
		display: none;
	}

	.bot-chat-header__link {
		margin-right: 8px;
		font-size: 15px;
		line-height: 20px;
	}

	.bot-chat-header__link:last-of-type {
		margin-right: 0;
	}
}

@media screen and (max-width: 500px) {
	.main__header.bot-chat-header {
		flex-direction: column;
		padding: 18px 0 0 0;
		background: #f2effe;
	}

	.bot-chat-header__links {
		padding: 16px 0 18px 0;
		width: 100%;
		background: #eeeafe;
		justify-content: center;
	}

	.bot-chat-header__link {
		margin-right: 60px;
	}

	.bot-chat-header__link:last-of-type {
		margin-right: 0;
	}

	.bot-chat-header__logo {
		margin-bottom: 18px;
	}

	.main__header.main__header_logged-mobile {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 18px 20px;
		box-sizing: border-box;
	}

	.main__header.main__header_logged-mobile .user-logo__image {
		width: 36px;
		height: 36px;
	}
}
