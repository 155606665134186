.text-area {
  border-radius: 16px;
  border: none;
  resize: none;
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  color: var(--secondary-color);

  background-color: white;
  padding: 18px;
  width: calc(100% - 2 * 18px);
  height: 24px;
  box-shadow: 0px 14px 24px rgba(36, 22, 91, 0.05);
  position: relative;
}

.text-area::placeholder {
  color: var(--light-purp-color);
}

.text-area_invalid {
  outline: 2px solid red;
}

.text-area.text-area_disabled {
  background-color: #f9f8fd;
}

.text-area__container {
  position: relative;
  width: 100%;
}

.text-area__disabled-layer {
  display: none;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
  z-index: 10;
  position: absolute;
  color: white;
  transition: opacity 1s ease-in-out;
  border-radius: 20px;
  font-size: 32px;
  line-height: 34px;
  top: 0;
}

.text-area__container:hover .text-area__disabled-layer {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s ease-in-out;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}
