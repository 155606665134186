.hidden {
  display: none;
}

.chat__avatar {
  object-fit: contain;
  border-radius: 20%;
}

.chat__avatar_top {
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
  top: 12px;
  left: calc(50% - 50px);
}

@media screen and (max-width: 630px) {
	.chat__avatar_top {
    width: 70px;
    height: 70px;
    left: auto;
    right: 50px;
	}
}