.MuiButtonBase-root.MuiChip-root.tag {
	background-color: white;
	color: var(--main-color);
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	font-family: 'Inter', sans-serif;
	padding: 14px 40px;
	margin-right: 14px;
	border-radius: 36px;
	box-shadow: 0px 0px 24px rgba(90, 52, 240, 0.2);
	height: 50px;
}

.MuiButtonBase-root.MuiChip-root.tag.tag_selected {
	background-color: black;
	color: white;
}

.tag__icon {
	margin-right: 12px;
	object-fit: contain;
}
