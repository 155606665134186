.in-modal__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 30px;
  background-color: white;

  align-items: flex-start;
}
