.user-logo-container {
	position: relative;
	height: 48px;
}

.user-logo__image {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	cursor: pointer;
}

.user-logo__tooltip {
	position: absolute;
	background-color: white;
	box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	top: 60px;
	right: 0;
	width: 240px;
	z-index: 105;
}

.signed-as {
	color: #8a88ac;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	padding: 16px 20px;
	text-align: start;
	border-bottom: 1px solid #8a88ac;
}

.tooltip-option {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding: 16px 20px;
	box-sizing: border-box;
	text-decoration: none;
	color: #292841;
	cursor: pointer;
}

.tooltip-option:not(div) {
	border-bottom: 1px solid #8a88ac;
}

.tooltip-option_img {
	margin-right: 12px;
}

.tooltip-option__text_bold {
	color: #292841;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
}
