.mobile-bot-page {
	display: flex;
	flex-direction: column;
	background: #d9d6e6;
}

.warning {
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	color: #fd5862;
	padding: 6px 0;
}

.mobile-bot-page .header {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 0;
	background-color: white;
	padding: 12px 20px;
}

.mobile-bot-page .header .back {
	margin-left: 10px;
}

.mobile-bot-page .header .profile {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0 auto 0 20px;
	cursor: pointer;
}

.mobile-bot-page .header .profile .avatar {
	width: 38px;
	height: 38px;
	border-radius: 5px;
	margin-right: 10px;
}

.mobile-bot-page .header .profile .texts {
	display: flex;
	flex-direction: column;
	max-width: 80%;
}

.mobile-bot-page .header .profile .texts .name {
	font-weight: 800;
	font-size: 17px;
	line-height: 24px;
	text-align: start;
}

.mobile-bot-page .header .profile .texts .subtitle {
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: 0.02em;
	color: black;
}

.mobile-bot-page .content {
	overflow-x: auto;
	padding: 8px 0px 8px 20px;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.mobile-bot-page .content .chat__message_bot {
	color: var(--secondary-color);
	background: linear-gradient(0deg, #f8f8fd, #f8f8fd), #d9d6e4;
}

.mobile-bot-page .content .chat__message_user {
	color: white;
	background-color: var(--main-color);
	margin-left: auto;
	margin-right: 8px;
}

.mobile-bot-page .input-field {
	margin: auto auto 6px auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: #ffffff;
	border: 2px solid #5a34f0;
	border-radius: 24px;
	width: calc(100% - 2 * 24px);
	border-radius: 24px;
	padding: 12px;
}

.mobile-bot-page .input-field .input {
	resize: none;
	color: black;
	background: white;
	border: none;
	width: calc(100% - 36px - 2 * 6px);
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	margin-right: 6px;
	font-family: 'Inter', sans-serif;
}

.mobile-bot-page .input-field .input::placeholder {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.374px;
	color: #8a89ab;
}

.mobile-bot-page .input-field .input:focus {
	outline: none;
	border: none;
}

.mobile-bot-page .input-field .input:focus-visible {
	outline: none;
	border: none;
}

.content::-webkit-scrollbar {
	width: 8px;
	height: 10px;
}

.content::-webkit-scrollbar-track {
	border-radius: 20px;
}

.content::-webkit-scrollbar-thumb {
	background-color: #adacc5;
	border: none;
	border-radius: 20px;
}

@media screen and (max-width: 500px) {
	.mobile-bot-page .content {
		overflow-x: hidden;
		overflow-y: scroll;
	}
}
