.bot-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 100%;
	background: linear-gradient(180deg, rgb(219 213 245), rgb(230 228 244)),
		#f1eeff;
	box-sizing: border-box;
	padding: 40px;
}

.bot-info__name {
	margin: 0;
	font-weight: 800;
	font-size: 32px;
	line-height: 40px;
	text-align: start;
}

.bot-info__messages-qty {
	margin: 12px 0 0 0;
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
	color: #8a89ab;
}

.bot-info__description {
	margin: 12px 0 24px 0;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: start;
	color: #292841;
}

.bot-info__author {
	margin: 12px 0 0 0;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #8a88ac;
}

.bot-info__action-btns {
	margin: 24px 0 0 0;
}

.bot-info__share-block {
	margin: 40px 0 0 0;
}

.bot-info__share-title {
	margin: 0;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	text-align: start;
}

.bot-info__share-row {
	margin: 12px 0 0 0;
	flex-wrap: wrap;
}

.bot-info__purchase {
	margin: auto 0 0 0;
	height: 185px;
	background: linear-gradient(90deg, #5a34f0 0%, #7034f0 100%), #5a34f0;
	border-radius: 20px;
	color: white;
	padding: 20px;
	text-align: start;
}

.bot-info__purchase-desc {
	margin: 20px 0 0 0;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	color: #8a88ac;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.bot-info__purchase-desc-text {
	text-align: justify;
}

.bot-info__purchase-desc-link {
	color: inherit;
}

.bot-info__messages-qty-text {
	margin-left: 6px;
	font-weight: 700;
	font-size: 14px;
}

.btn__text {
	margin-left: 6px;
}

.MuiButtonBase-root.bot-info__report-btn {
	margin-left: 16px;
}

.bot-info__round-btn {
	width: 44px;
	height: 44px;
	border-radius: 50%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: white;

	margin-right: 12px;
	cursor: pointer;
}

.bot-info__round-btn:last-of-type {
	margin-right: 0;
}

.bot-info__purchase-title {
	font-weight: 800;
	font-size: 24px;
	line-height: 30px;
}

.bot-info__purchase-text {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	margin: 12px 0;
}

.bot-info__purchase-price {
	font-weight: 900;
	font-size: 28px;
	line-height: 33px;
	color: yellow;
	margin-right: 6px;
}

.bot-info__purchase-row {
	align-items: center;
	justify-content: space-between;
}

.purchase-btn.MuiButton-contained {
	color: #5a34f0;
	background-color: white;
}

.purchase-btn.MuiButton-contained:hover {
	background-color: #c5baf274;
	color: white;
}

.bot-info__avatar {
	width: 280px;
	aspect-ratio: 1/1;
	border-radius: 24px;
	margin-bottom: 24px;
}

.bot-info__header-text {
	font-weight: 900;
	font-size: 20px;
	line-height: 24px;
	color: #8a88ac;
	margin-bottom: 16px;
}

@media screen and (max-width: 1150px) {
	.bot-info {
		padding: 20px;
	}

	.bot-info__avatar {
		width: 180px;
	}

	.bot-info__purchase {
		height: 230px;
	}
}

@media screen and (max-width: 500px) {
	.bot-info__avatar {
		display: none;
	}

	.bot-info__round-btn {
		margin-right: 15px;
	}

	.bot-info__purchase-desc {
		display: none;
	}

	.bot-info {
		margin-top: 32px;
	}

	.bot-info__purchase {
		display: none;
	}
}
