.bot-page-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color);
}

.bot-page {
  /* min-height: 1000px; */
  height: calc(100% - 100px);
  background-color: var(--bg-color);
}

.bot-page-content {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 360px 1fr 360px;
  gap: 0;
  height: 100%;
}

.bot-info_other {
  overflow-y: scroll;
  height: 80%;
}

.bot-info__other-bots {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bot-info__others-block {
  width: 100%;
}

.bot-info__others-block:not(:first-of-type) {
  margin-top: 32px;
}

.bot-info__header-text {
  text-align: start;
}

.bot-page__other {
  margin-bottom: 100px;
}

@media screen and (max-width: 1150px) {
  .bot-page-content {
    grid-template-columns: 260px 1fr 220px;
  }
}

@media screen and (max-width: 500px) {
  .bot-info__others-block:last-of-type {
    margin-bottom: 100px;
  }
}
