.like-report {
	width: 100%;
}

.like-report__btn.MuiButtonBase-root.MuiButton-root {
	border-radius: 30px;
	width: 45%;
	font-family: 'Inter';
}

.like-report__btn.MuiButtonBase-root.MuiButton-root img {
	margin-right: 10px;
}

.like-report__btn--like.MuiButtonBase-root.MuiButton-root {
	margin-right: 16px;
}

.like-report__btn--report.MuiButtonBase-root.MuiButton-root {
	border-color: #8a89ab;
	color: #8a89ab;
}

.like-report__btn--report.MuiButtonBase-root.MuiButton-root:hover {
	border-color: #8a89ab;
	color: #8a89ab;
	background-color: lightgray;
}

.profile-modal .like-report__btn--like.inbutton.MuiButton-outlined:hover {
	background-color: inherit;
	color: var(--main-color);
	border-width: 2px;
	border-color: var(--main-color);
}

.profile-modal .like-report__btn--report.inbutton.MuiButton-outlined:hover {
	border-color: #8a89ab;
	color: #8a89ab;
	background-color: inherit;
}

@media screen and (max-width: 1150px) and (min-width: 800px) {
	.like-report__icon {
		display: none;
	}
}
