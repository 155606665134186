.feedback-modal__inner {
	width: 540px;
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow-y: auto;
	box-sizing: border-box;
	font-family: 'Inter';
}

.feedback-modal__inner .feedback-modal__title {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 900;
	font-size: 32px;
	line-height: 40px;
}

.feedback-modal__inner .feedback-send.inbutton.MuiButtonBase-root {
	font-family: 'Inter';
	border-radius: 30px;
	width: 200px;
}

.feedback-modal__inner .feedback-text {
	background-color: #e9e8ef;
	margin: 30px 0;
	min-height: 160px;
	font-family: 'Inter';
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
}

.feedback-send__text {
	margin-left: 10px;
}

@media screen and (max-width: 600px) {
	.feedback-modal__inner {
		width: 325px;
		padding: 20px;
	}

	.feedback-modal__inner .feedback-modal__title {
		font-size: 20px;
		line-height: 24px;
	}

	.feedback-modal__inner .feedback-text {
		min-height: 160px;
		font-size: 16px;
		line-height: 20px;
		margin: 20px 0;
	}

	.feedback-modal__inner .feedback-send.inbutton.MuiButtonBase-root {
		width: 100%;
	}

	.feedback-modal__inner .modal__close {
		width: 20px;
		height: 20px;
	}
}

@media screen and (max-width: 345px) {
	.feedback-modal__inner {
		width: 255px;
		padding: 15px;
	}

	.feedback-modal__inner .feedback-modal__title {
		font-size: 16px;
		line-height: 20px;
	}

	.feedback-modal__inner .feedback-text {
		min-height: 160px;
		font-size: 14px;
		line-height: 16px;
		margin: 20px 0;
	}
}
