.unsubscribe-modal__inner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 420px;
	padding: 40px;
	box-sizing: border-box;

	border-radius: 30px;
	background-color: white;

	align-items: center;
	justify-content: flex-start;
	overflow-x: hidden;
}

.unsubscribe__title {
	font-weight: 900;
	font-size: 27px;
	line-height: 32px;
	/* or 119% */

	text-align: center;

	width: 190px;

	color: #292841;
}

.unsubscribe__text {
	margin-top: 32px;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	/* or 122% */

	text-align: center;

	/* Text/Normal */

	color: #292841;
}

.unsubscribe__text span {
	font-weight: 700;
}

.btns {
	margin-top: 32px;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
}

.btns__cancel.inbutton.MuiButtonBase-root {
	background-color: #8a88ac;
	color: white;
}

.btns__unsubscribe.inbutton.MuiButtonBase-root {
	background-color: #fd5862;
	color: white;
}

@media screen and (max-width: 500px) {
	.unsubscribe-modal__inner {
		width: calc(100% - 2 * 20px);
	}

	.btns {
		grid-template-columns: 1fr;
	}
}
