.messages-qty {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	font-weight: 700;
	font-size: 12px;
	line-height: 15px;

	color: #5C6269;
}

.messages-qty__bubble {
	width: 14px;
	height: 14px;
}

.messages-qty__text {
	margin-left: 6px;
}