.profile-modal .MuiPaper-root {
	background: #e9e8ef;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: black;
	padding: 24px;
	text-align: center;
}

.profile-modal .avatar {
	width: 120px;
	height: 120px;
	border-radius: 16px;
}

.profile-modal .name {
	font-weight: 800;
	font-size: 34px;
	line-height: 40px;
	margin-top: 24px;
	margin-bottom: 10px;
}

.profile-modal .messages-row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: #5c6269;
	font-size: 14px;
	line-height: 20px;
}

.profile-modal .messages-row .text {
	margin-left: 4px;
}
.profile-modal .buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 28px;
}

.profile-modal .description {
	margin-top: 32px;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 24px;
}

.profile-modal .MuiDialog-container.MuiDialog-scrollPaper {
	height: 90vh;
}

.profile-modal .other-profs {
	margin-top: 32px;
	width: 100%;
}

.profile-modal .other-prof-title {
	color: #8a88ac;
	font-weight: 900;
	font-size: 18px;
	line-height: 24px;
  margin-bottom: 16px;
}

.profile-modal .messages-qty__text {
	margin-top: 2px;
}