.big-card {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 80px 80px 60px;
	border-radius: 40px;
	width: 580px;
	height: 614px;
	box-sizing: border-box;
	margin-right: 40px;
}

.big-card:last-of-type {
	margin-right: 0;
}

.big-card_red {
	background: rgba(255, 0, 0, 0.04);
}

.big-card_green {
	background: rgba(133, 195, 61, 0.1);
}

.big-card__text {
	margin-top: 48px;
	font-weight: 900;
	font-size: 40px;
	line-height: 48px;
	width: 100%;
	text-align: start;
}

.big-card__list {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	list-style-type: none;
	width: 100%;
}

.big-card__list .list-text {
	margin-left: 12px;
	font-weight: 500;
	font-size: 21px;
	line-height: 28px;
	text-align: start;
}

.big-card__list li {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 12px;
}

@media screen and (max-width: 1300px) {
	.big-card {
		padding: 30px;
		width: 450px;
	}
}

@media screen and (max-width: 900px) {
	.big-card {
		width: 80%;
		margin-right: 0;
		margin-bottom: 40px;
		padding: 60px;
	}

	.big-card__list {
		width: fit-content;
	}

	.big-card__text {
		text-align: center;
	}
}

@media screen and (max-width: 758px) {
	.big-card {
		width: 100%;
		padding: 40px;
		height: auto;
	}

	.big-card .main-img {
		width: 128px;
		height: 128px;
	}

	.big-card__text {
		margin-top: 30px;
		font-size: 27px;
		line-height: 36px;
	}

	.big-card__list .list-text {
		font-size: 18px;
		line-height: 24px;
	}
}
