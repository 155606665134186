* {
	padding: 0;
	margin: 0;
	/* outline: 1px solid red; */
}

@import url(./common-lib/src/styles.css);

:root {
	--xl-screen: 1300px;
}

body {
	color: black;
	background-color: #eae6fb;
}

.inbutton.MuiButton-contained:hover {
	filter: brightness(1.2);
	transition: 0.6s;
}

.inbutton.MuiButton-contained:disabled {
	opacity: 0.6;
	color: black;
}

.inbutton.MuiButton-outlined.inbutton_inverted {
	color: white;
	background-color: transparent;
	border-color: white;
}

.inbutton.MuiButton-outlined:hover.inbutton_inverted {
	background-color: white;
	color: var(--main-color);
	border-color: white;
}

.App .inbutton {
	font-family: 'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
	font-weight: 800 !important;
}

@media screen and (max-width: 830px) {
	.App .header {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.App .header__wrapper {
		margin-top: 0px;
	}
}
