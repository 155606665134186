.prof {
	width: 280px;
	min-height: 450px;
	background: #f8f8fd;
	border-radius: 30px;
	box-shadow: 0px 0px 24px rgba(90, 52, 240, 0.2);
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
	margin: 0 auto;
}

.prof_locked {
	cursor: default;
}

.prof_hovered {
	align-items: center;
	justify-content: flex-start;
	background: #ffffff;
	box-shadow: 0px 4px 24px rgba(90, 52, 240, 0.25);
}

.prof .avatar {
	width: 220px;
	height: 220px;
	border-radius: 20px;
}

.prof.prof_locked .lock-img {
	margin-right: 10px;
}

.prof.prof_locked .avatar {
	opacity: 0.5;
}

.prof .title {
	font-weight: 900;
	font-size: 20px;
	line-height: 27px;
	margin-top: 20px;
	text-align: center;
}

.prof .helped {
	margin-top: 12px;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	color: #8a88ac;
}

.prof .desc {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin-bottom: 20px;
}

.prof .avatar_hovered {
	width: 42px;
	height: 42px;
	border-radius: 8px;
}

.prof .title_hovered {
	font-weight: 900;
	font-size: 16px;
	line-height: 22px;
	margin-left: 16px;
	text-align: start;
}

.prof .question {
	background: rgba(236, 235, 255, 0.5);
	border-radius: 12px;
	padding: 15px;
	cursor: pointer;
	margin-bottom: 16px;
	text-align: start;
}

.prof .question:hover {
	background: #ecebff;
	color: #5a34f0;
}

.prof .MuiButtonBase-root.inbutton {
	margin-top: auto;
	border-radius: 36px;
	padding: 12px 40px;
}

@media screen and (max-width: 758px) {
	.prof {
		width: 160px;
		min-height: 302px;
		padding: 16px;
		border-radius: 22px;
	}

	.prof .avatar {
		width: 128px;
		height: 128px;
		border-radius: 20px;
	}

	.prof .title {
		font-size: 16px;
		line-height: 20px;
		margin-top: 12px;
	}

	.prof .helped {
		margin-top: 8px;
		font-size: 11px;
		line-height: 14px;
	}

	.prof .MuiButtonBase-root.inbutton {
		margin-top: auto;
		padding: 8px 27px;
		font-size: 14px;
		line-height: 20px;
		height: 36px;
	}
}
