.chat__message {
  display: table;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 19px;
  max-width: 70%;
  text-align: start;
  border-radius: 14px;
  padding: 10px;
  margin-top: 20px;
}

.chat__message_user {
  align-self: flex-end;
  background-color: #d9d6e6;
}

.chat__message_bot {
  align-self: flex-start;
  background-color: #f8f8fd;
}

.message__text {
  margin: 0;
}

.chat__message-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.chat__message-row .chat__message {
  margin-top: 0;
}

.message-row__photo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 8px;
}

.chat__message_bot_typing {
  width: 30px;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
  margin-left: 10px;
  top: 5px;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

.chat__message_bot-image {
  width: 200px;
}

@keyframes dotFlashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}


@media screen and (max-width: 630px) {
  .chat__message_user {
    background-color: var(--main-color);
    color: white;
    border-bottom-right-radius: 3px;
  }

  .chat__message_bot {
    border-bottom-left-radius: 3px;
  }
}