.verify__container {
  padding: 50px;
}

.verify__title {
  margin-bottom: 20px;
}

.verify__text {
  color: black;
}

.verify__text {
  margin-bottom: 10px;
}

.verify__text:last-of-type {
  margin-bottom: 32px;
}
