.chat {
  display: grid;
  grid-template-areas:
    'side header'
    'side  main'
    'side  foot';
  grid-template-rows: 70px 1fr 50px;
  grid-template-columns: 303px 1fr;
  min-width: 580px;
  box-sizing: border-box;
}

.chat-block__chat {
  filter: drop-shadow(0px 18px 56px rgba(90, 52, 240, 0.2));
  background-color: white;
}

@media screen and (max-width: 1070px) {
  .chat {
    grid-template-columns: 200px 1fr;
  }
}

.chat__sidebar {
  grid-area: side;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  background-color: var(--chat-bg-color);
  border-right: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

.chat__avatar_small {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.chat__row {
  margin-bottom: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  box-sizing: border-box;
}

.chat__name {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: black;
  margin: 0 0 0 14px;
  text-align: start;
}

.chat__main .smart-replies__row .reply {
  border: 1px solid var(--main-color);
}

.chat__main .smart-replies__row .reply:hover {
  background: var(--main-color);
  opacity: 0.8;
  border: 1px solid var(--main-color);
  color: white;
}

.chat__name_create {
  color: var(--main-color);
}

.chat__header {
  grid-area: header;
  background-color: var(--chat-bg-color);
  border-top-right-radius: var(--border-radius);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  font-weight: 900;
  font-size: 20px;
  line-height: 28px;
  color: var(--secondary-color);
  position: relative;
}

.chat__footer {
  grid-area: foot;
  background-color: white;
  border-bottom-right-radius: var(--border-radius);
  border-top: 1px solid var(--border-color);
}

.chat__footer .MuiPaper-root {
  box-shadow: none;
}

.chat__main {
  background-color: white;
  color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  margin-top: auto;
  min-height: 100%;
}

.chat__main-container {
  display: block;
  overflow-y: auto;
  padding: 0 10px 0 0;
}

.smart-replies__row .reply:hover {
  border-width: 1px;
}

.smart-replies__row .reply:not(:last-of-type) {
  margin-right: 10px;
}

.simple-replies {
  display: flex;
  flex-direction: column;
}

.simple-replies .chat__main {
  height: 100%;
}

.simple-replies .chat__footer {
  border-bottom-left-radius: var(--border-radius);
}

.simple-replies .chat__footer .MuiPaper-root {
  border-bottom-left-radius: var(--border-radius);
}

.simple-replies .chat__header {
  border-top-left-radius: var(--border-radius);
}

.chat__avatar_selected {
  box-shadow: 0 0 0 4px white, 0 0 0 6px var(--main-color);
}

@media screen and (max-width: 630px) {
  .chat {
    grid-template-columns: 60px 1fr;
    min-width: 300px;
    position: relative;
  }

  .chat__row {
    margin-bottom: 16px;
  }

  .chat__avatar_small {
    width: 30px;
    height: 30px;
  }

  .chat__name {
    display: none;
  }

  .chat__sidebar {
    padding: 15px;
    box-sizing: border-box;
  }

  .smart-replies__row {
    padding: 0;
    font-size: 14px;
    line-height: 16px;
  }
}

.chat-block__chat {
  width: 1200px;
  height: 640px;
  border-radius: 30px;
  background-color: white;
  border-radius: var(--border-radius);
  filter: drop-shadow(0px 18px 56px rgba(90, 52, 240, 0.2));

  animation-duration: 1s;
  animation-fill-mode: both;
  margin-left: auto;
  margin-right: auto;
}

.chat-block__chat .chat__avatar_top {
  width: 128px;
  height: 128px;
  right: 10px;
  left: auto;
}

.chat__category-select {
  margin-bottom: 20px;
}

@media screen and (max-width: 1480px) {
  .chat-block__chat {
    width: 944px;
  }
}

@media screen and (max-width: 1300px) {
  .chat-block__chat {
    width: 960px;
  }
}

@media screen and (max-width: 1000px) {
  .chat-block__chat {
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
    height: 360px;
  }

  .chat-block__chat .chat__avatar_top {
    width: 60px;
    height: 60px;
    right: 10px;
    top: 5px;
    left: auto;
  }

  .chat-block .chat__main .chat__message_user {
    border-radius: 16px 16px 4px 16px;
    background: var(--text-placeholder-alt, #adacc5);
  }

  .chat__header {
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px; /* 125% */
  }
}
