.category-select {
  border-radius: 16px;
  border: 1px solid var(--main-bg, #e9e8ef);
  background: var(--system-white, #fff);
  width: 100%;
  color: black;
  padding: 13px 20px;
  box-sizing: border-box;
  text-align: left;
  font-weight: 700;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.category-select__inner {
  display: none;

  flex-direction: column;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  overflow-y: scroll;

  top: 55px;
  left: 0px;
  width: 100%;
  height: 360px;

  z-index: 100;
}

.category-select__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.category-select__open-img {
  rotate: 90deg;
}

.category-select__open-img_openned {
  rotate: 270deg;
}

.category-select__inner_opened {
  display: flex;
}

.category-select__item {
  display: flex;
  flex-direction: row;
  padding: 16px 20px;
  font-weight: 400;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.category-select__item:not(:first-child) {
  border-top: 1px solid #e9e8ef;
}

.category-select__item_selected {
  font-weight: 700;
}

.category-select__check {
  color: #5a34f0;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (min-width: 630px) {
  .category-select {
    position: relative;
  }
}
@media screen and (max-width: 630px) {
  .category-select__inner {
    top: 50px;
    left: 12px;
    width: calc(100% - 24px);
  }

  .category-select {
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 20%;
    justify-content: center;
    padding: 5px 0;
    font-weight: 700;
  }

  .category-select__header {
    justify-content: center;
  }
}
