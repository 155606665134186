.subscription__container {
  margin-top: 40px;
  max-width: 1200px;
  width: 80%;
  height: fit-content;
  margin: 40px auto;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(90, 52, 240, 0.1);
  border-radius: 40px;
  box-sizing: border-box;
  padding: 60px;
}

.subsciption__header {
  display: flex;
  justify-content: space-between;
}

.subscription__title {
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
}

.subscription__billing-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscription__billing-link-img {
  margin-left: 12px;
}

.subscription__info-block {
  margin-top: 44px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.subscription__info-block_short {
  grid-template-columns: repeat(2, 1fr);
}

.info-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e9e8ef;
  border-radius: 16px;
  height: 100px;
}

.info-card__title {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.1px;

  color: #8a88ac;
}

.info-card__text {
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.1px;

  color: #292841;
}

.info-card__text_red {
  color: red;
}

.subscription__plans-container {
  margin-top: 40px;
  background: #f8f8fd;

  border: 1px solid #e9e8ef;
  border-radius: 36px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  box-sizing: border-box;
}

.subscription__plans-title {
  font-weight: 900;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.1px;
  color: #292841;
}

.subscription__plans-subtitle {
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #292841;
  margin-top: 7px;
}

.subscription__plans {
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
}

.subscription__plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e9e8ef;
  border-radius: 24px;
  position: relative;
  padding: 36px;
  box-sizing: border-box;
}

.plan__title {
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.1px;
  color: #292841;
  margin-top: 20px;
}

.plan__title-row .plan__title {
  margin-top: 0;
}

.plan__subtitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #8a88ac;
  margin-top: 7px;
}

.plan__price {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: #292841;
  margin: 16px 0;
}

.plan__subprice {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  margin-top: auto;

  color: #8a88ac;
}

.plan__price_extra {
  margin-top: calc(16px + 19px + 20px);
}

.plan__btn.inbutton.MuiButtonBase-root {
  width: 100%;
  margin-top: auto;
}

.plan__btn_active.inbutton.MuiButtonBase-root {
  background-color: #8a88ac;
}

.plan__sale {
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  position: absolute;
  width: 109px;
  height: 26px;
  left: calc(50% - 109px / 2 + 1px);
  top: -12px;

  background: #16ba44;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan__title-row {
  display: flex;
  flex-direction: column;
}

.period-select {
  margin-left: 12px;
  padding: 4px 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #e9e8ef;
  border-radius: 8px;
  color: #5a34f0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  position: relative;
}

.period-select_opened {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.period-select__options {
  position: absolute;
  top: 100%;
  left: 0%;
  z-index: 2;
  width: 100%;
  background: #e9e8ef;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #5a34f0;
}

.period-select__option {
  padding: 4px 12px;
  box-sizing: border-box;
  text-align: start;
}

.period-select__img {
  margin-left: 6px;
}

.plan__list {
  min-height: 104px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #292841;
  margin-top: 16px;
  list-style-position: inside;
  list-style: none;
  width: 100%;
  text-align: start;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.plan__list-item {
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  margin-top: 5px;
  display: flex;
}

.plan__list-text {
  margin-left: 6px;
}

.plan__switcher {
  margin-bottom: 6px;
}

@media screen and (max-width: 768px) {
  .subsciption__header {
    flex-direction: column;
    align-items: center;
  }

  .subscription__container {
    height: auto;
    padding: 40px 20px;
  }

  .subscription__info-block {
    grid-template-columns: repeat(2, 1fr);
  }

  .subscription__plans {
    grid-template-columns: repeat(1, 1fr);
  }

  .subscription__plans-title {
    text-align: center;
  }

  .subscription__plans-subtitle {
    text-align: center;
  }

  .subscription__plan {
    margin-bottom: 30px;
  }
}
